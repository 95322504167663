.header {
    border-bottom: solid 1px #ddd;
    margin-bottom: 36px;
    
    .header-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h1 {
            font-size: 1.2rem;
            flex: 1 1;
        }

    }
}