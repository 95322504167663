.home {
    .hero-image {
        background: url("./../assets/pomegranate.jpg");
        background-size: cover;
        background-position: center center;
        min-height: 40vh;
        width: 100%;
        margin-bottom: 36px;
    }

    .site-content {
        max-width: 40rem;
        margin: auto;
    }
}